<template>
    <div class="Client">
        <div v-if="isLoading" class="loadingBox">
            <loading-new :loading="isLoading" class="loading-center-absolute" :show1="true" v-if="isLoading"></loading-new>
            <div class="loadingText">
                <!-- 首次进入客户模块<br>配置数据加载中...<br>请稍等 -->
                {{$t('mx_mobile.Client.1585121884169')}}
                <br>
                {{$t('mx_mobile.Client.1585121891778')}}
                <br>
                {{$t('mx_mobile.Client.1626676751575')}}
            </div>
        </div>
        <keep-alive v-if="!isLoading" style="z-index:11">
            <router-view class="ClientBox" :sysBoxValue="sysBoxValue" :AONIcustSort="AONIcustSort" :currency="currency" :cusBoxValue="cusBoxValue"></router-view>
        </keep-alive>
    </div>
</template>

<script>
// import { mapMutations } from 'vuex'
import { mapGetters, mapActions } from 'vuex'
import loadingNew from './CustPublic/LoadingVue/Vue/loading3.vue'
import titleMixin from '@/mixin/title'
export default {
    name: 'Client',
    title: function fc() { return this.$t('mx_mobile.Client.1584681452354') }, // 客户
    mixins: [titleMixin],
    data() {
        return {
            isLoading: true
        }
    },
    created() {
    },
    mounted() {
        if (this.sysBoxValue && this.sysBoxValue.length > 0) {
            this.isLoading = false
            this.toPage(true)
        } else {
            this.isLoading = true
            this.setSysBoxValue(this.toPage, this.clearLoding)
        }

        if (!this.currency || this.currency.length == 0) {
            this.setCurrency()
        }
    },
    computed: {
        ...mapGetters(['sysBoxValue', 'cusBoxValue', 'AONIcustSort', 'currency'])
    },
    methods: {
        // 个人信息存起来
        ...mapActions([
            'setSysBoxValue',
            'setCurrency'

        ]),
        clearLoding() {
            this.isLoading = false
        },
        toPage(clear, error) {
            if (!clear) {
                this.isLoading = false
            }
            if (error) {
                return false
            }
            if (this.$route.path === '/client' || this.$route.path === '/client/') {
                this.$router.replace('/client/home')
            }
            if (this.$route.path === '/client' || this.$route.path === '/client/' || this.$route.path === '/client/home' || this.$route.path === '/client/seas' || this.$route.path === '/client/focus' || this.$route.path === '/client/queryRepeat') {
                this.quickAdd() // 全局右上角快捷新增
            }
        }
    },
    watch: {
        $route() {
            this.toPage()
        }
    },
    components: {
        'loading-new': loadingNew
    }
}
</script>

<style lang='less' rel='stylesheet/less' scoped>
.ClientBox {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}
.loadingBox {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99999;
    .loadingText {
        .width(160px);
        display: block;
        margin: 0 auto;
        text-align: center;
    }
}

.loading-center-absolute {
    .height(20px);
    .loadingText {
        .left(-40px);
        .top(80px);
        position: absolute;
        .width(160px);
        margin: 0 auto;
        text-align: center;
        .font-size(12px);
        color: #909399;
    }
}
</style>
